import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FacebookIcon, TwitterIcon, LinkedinIcon } from 'react-share';

const Footer = () => {
  return (
    <>
      <div className="clearfix" />
      <div className="ui-pre-footer mt-6">
        <Container>
          <Row>
            <Col md={6} lg={3}>
              <h6 className="ui-pre-footer__title">Industries</h6>
              <ul className="ui-pre-footer__list unstyled">
                <li>
                  <Link
                    className="ui-pre-footer__link"
                    to="/hospital-app-development/"
                  >
                    Hospitals
                  </Link>
                </li>
                <li>
                  <Link
                    className="ui-pre-footer__link"
                    to="/integrated-delivery-networks-web-app-development/"
                  >
                    Integrated Delivery Networks
                  </Link>
                </li>
                <li>
                  <Link
                    className="ui-pre-footer__link"
                    to="/outpatient-ambulatory-clinic-web-app-development/"
                  >
                    Clinics / Outpatient Groups
                  </Link>
                </li>
                <li>
                  <Link
                    className="ui-pre-footer__link"
                    to="/health-it-vendors/"
                  >
                    Health IT Vendors
                  </Link>
                </li>
                <li>
                  <Link
                    className="ui-pre-footer__link"
                    to="/pharmacy-web-app-development/"
                  >
                    Pharmacies
                  </Link>
                </li>
                <li>
                  <Link
                    className="ui-pre-footer__link"
                    to="/custom-lab-result-portal-development/"
                  >
                    Laboratories
                  </Link>
                </li>
                <li>
                  <Link
                    className="ui-pre-footer__link"
                    to="/medical-device-web-mobile-app-development/"
                  >
                    Medical Device Manufacturers
                  </Link>
                </li>
                <li>
                  <Link
                    className="ui-pre-footer__link"
                    to="/physician-practices/"
                  >
                    Physician Practices
                  </Link>
                </li>
                <li>
                  <Link
                    className="ui-pre-footer__link"
                    to="/healthcare-nonprofit-website-development/"
                  >
                    Nonprofits & Charities
                  </Link>
                </li>
              </ul>
            </Col>
            <Col md={6} lg={3}>
              <h6 className="ui-pre-footer__title">
                Mobile App & Portal Development
              </h6>
              <ul className="ui-pre-footer__list unstyled">
                <li>
                  <Link
                    className="ui-pre-footer__link"
                    to="/healthcare-mobile-app-development/"
                  >
                    Healthcare Mobile App Development
                  </Link>
                </li>
                <li>
                  <Link
                    className="ui-pre-footer__link"
                    to="/custom-health-patient-portal-development/"
                  >
                    Healthcare Web App & Portal Development
                  </Link>
                </li>
                <li>
                  <Link
                    className="ui-pre-footer__link"
                    to="/patient-portal-app-development/"
                  >
                    Patient Portal App Development
                  </Link>
                </li>
                <li>
                  <Link
                    className="ui-pre-footer__link"
                    to="/custom-patient-scheduling-software-app-development/"
                  >
                    Custom Patient Scheduling Software and App Development
                  </Link>
                </li>
                <li>
                  <Link
                    className="ui-pre-footer__link"
                    to="/digital-front-door-development/"
                  >
                    Digital Front Door
                  </Link>
                </li>
                <li>
                  <Link
                    className="ui-pre-footer__link"
                    to="/healthcare-emr-ehr-interface-development/"
                  >
                    EMR/EHR Interface Development
                  </Link>
                </li>
              </ul>
              <h6 className="ui-pre-footer__title">HIPAA Hosting</h6>
              <ul className="ui-pre-footer__list unstyled">
                <li>
                  <Link
                    className="ui-pre-footer__link"
                    to="/hipaa-compliant-website-email-hosting/"
                  >
                    HIPAA-compliant Web Hosting
                  </Link>
                </li>
                <li>
                  <Link
                    className="ui-pre-footer__link"
                    to="/hipaa-hosting-mwe-cloud/"
                  >
                    HIPAA Hosting via MWE Cloud
                  </Link>
                </li>
                <li>
                  <Link
                    className="ui-pre-footer__link"
                    to="/hipaa-compliant-website/"
                  >
                    HIPAA-compliant Website Design
                  </Link>
                </li>
              </ul>
            </Col>
            <Col md={6} lg={3}>
              <h6 className="ui-pre-footer__title">Web Design</h6>
              <ul className="ui-pre-footer__list unstyled">
                <li>
                  <Link
                    className="ui-pre-footer__link"
                    to="/healthcare-website-design/"
                  >
                    Healthcare Website Design
                  </Link>
                </li>
                <li>
                  <Link
                    className="ui-pre-footer__link"
                    to="/healthcare-mobile-responsive-web-design/"
                  >
                    Healthcare Mobile-responsive Web Design
                  </Link>
                </li>
                {/* <li>
                  <Link className="ui-pre-footer__link" to="/">
                    Is Your Site Mobile-friendly?
                  </Link>
                </li> */}
              </ul>
            </Col>
            <Col md={6} lg={3}>
              <h6 className="ui-pre-footer__title">Patient Engagement</h6>
              <ul className="ui-pre-footer__list unstyled">
                <li>
                  <Link
                    className="ui-pre-footer__link"
                    to="/patient-engagement-strategy/"
                  >
                    Patient Engagement Strategy
                  </Link>
                </li>
                <li>
                  <Link
                    className="ui-pre-footer__link"
                    to="/patient-engagement/"
                  >
                    Patient Engagement Software System
                  </Link>
                </li>
              </ul>
              <h6 className="ui-pre-footer__title">More Info</h6>
              <ul className="ui-pre-footer__list unstyled">
                <li>
                  <Link className="ui-pre-footer__link" to="/about-us/">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link className="ui-pre-footer__link" to="/portfolio/">
                    Portfolio
                  </Link>
                </li>
                <li>
                  <Link className="ui-pre-footer__link" to="/services/">
                    Our Services
                  </Link>
                </li>
                <li>
                  <Link className="ui-pre-footer__link" to="/blog/">
                    Blog
                  </Link>
                </li>
                <li>
                  <Link className="ui-pre-footer__link" to="/newsroom/">
                    Newsroom
                  </Link>
                </li>
                <li>
                  <Link className="ui-pre-footer__link" to="/support/">
                    Support
                  </Link>
                </li>
                <li>
                  <Link className="ui-pre-footer__link" to="/contact-us/">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <footer className="ui-footer">
        <div className="ui-footer__misc">
          <span className="ui-footer__misc-item bg-secondary" />
          <span className="ui-footer__misc-item bg-accent" />
          <span className="ui-footer__misc-item bg-primary" />
        </div>
        <Container fluid="lg">
          <Row>
            <Col>
              <ul className="ui-footer__seals unstyled">
                <li>
                  <a
                    href="https://compliancy-group.com/hipaa-compliance-verification/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <StaticImage
                      src="../../images/site-core/seals/hipaa.png"
                      height={60}
                      alt="Hipaa Compliant"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.certmetrics.com/amazon/public/error.html?aspxerrorpath=/amazon/public/badge.aspx"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <StaticImage
                      src="../../images/site-core/seals/aws-seal-large.png"
                      height={60}
                      alt="AWS Certified Solutions Architect"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.google.com/partners/agency?id=7616129237"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <StaticImage
                      src="../../images/site-core/seals/medical-web-experts-google-adwords-premier-partner.png"
                      height={60}
                      alt="Google Adwords Premier Partnet"
                    />
                  </a>
                </li>
                <li>
                  <a href="/hipaa-compliant-website/">
                    <StaticImage
                      src="../../images/site-core/seals/hipaa-seal.jpg"
                      height={60}
                      alt="Hipaa Compliant Services"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://ehealthcareawards.com/2021-winners/best-digital-marketing-campaign/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <StaticImage
                      src="../../images/site-core/seals/ehla_badge_2021.png"
                      height={60}
                      alt="Winner EHealthcare Leadership Awards 2020"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.designrush.com/agency/profile/medical-web-experts"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <StaticImage
                      src="../../images/site-core/seals/design-rush-accredited.png"
                      height={60}
                      alt="Design Rush Accredited"
                    />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
          <Row className="justify-content-center mt-5 mb-3">
            <Col
              md={8}
              lg={6}
              className="ui-footer__legal-text text-center col-auto"
            >
              <p className="mt-0 mb-1 font-size-sm">
                © {new Date().getFullYear()} Medical Web Experts LLC
              </p>
              <p className="mt-0 mb-1 font-size-sm">
                <a
                  href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x80dc019322692135:0x5c7c05e483f2672?source=g.page.m"
                  target="_blank"
                  rel="noreferrer"
                >
                  5950 Sherry Ln, Ste 405A, Dallas, TX 75225
                </a>{' '}
                | Tel: <a href="tel:18669329944">1-866-932-9944</a>
              </p>
              <p className="mt-0 mb-1 font-size-sm">
                The experts in healthcare web and mobile app development,
                medical website design and marketing.{' '}
                <Link to="/mhealth-apps/">
                  Interested in learning more about our app?
                </Link>
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="text-center">
              <a
                href="https://www.facebook.com/medical.website.design"
                target="_blank"
                rel="noreferrer"
                className="ui-social-icons"
              >
                <FacebookIcon
                  size={32}
                  bgStyle={{ fill: '#444' }}
                  iconFillColor="white"
                  borderRadius={8}
                />
              </a>
              <a
                href="https://twitter.com/MedWebExperts/"
                target="_blank"
                rel="noreferrer"
                className="ui-social-icons"
              >
                <TwitterIcon
                  size={32}
                  bgStyle={{ fill: '#444' }}
                  iconFillColor="white"
                  borderRadius={8}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/medicalwebexpert/"
                target="_blank"
                rel="noreferrer"
                className="ui-social-icons"
              >
                <LinkedinIcon
                  size={32}
                  bgStyle={{ fill: '#444' }}
                  iconFillColor="white"
                  borderRadius={8}
                />
              </a>
              <a
                href="https://www.youtube.com/user/MedicalWebExperts"
                target="_blank"
                rel="noreferrer"
                className="ui-social-icons you-tube-icon"
              >
                <StaticImage
                  src="../../images/site-core/icons/you-tube-icon.svg"
                  alt="Youtube"
                />
              </a>
            </Col>
          </Row>
        </Container>
      </footer>
      <div className="ui-legal-footer">
        <Container>
          <Row className="justify-content-center">
            <Col className="col-auto">
              <ul className="ui-legal-footer--list unstyled">
                <li>
                  <Link
                    to="/service-level-agreement/"
                    className="ui-legal-footer--link text-decoration-none"
                  >
                    Service Level Agreement
                  </Link>
                </li>
                <li>
                  <Link
                    to="/privacy-policy/"
                    className="ui-legal-footer--link text-decoration-none"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/terms-of-use/"
                    className="ui-legal-footer--link text-decoration-none"
                  >
                    Terms of Use
                  </Link>
                </li>
                <li>
                  <Link
                    to="/sitemap/"
                    className="ui-legal-footer--link text-decoration-none"
                  >
                    Sitemap
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Footer;
