/**
 * Checks if the referrer is a real referrer and not navigation through the same (sub)domain
 * @return true/false
 */
export const isRealReferrer = () => {
  return document.referrer.split('/')[2] !== window.location.host;
};

/**
 * Checks if a string is empty.
 * @return false if empty or null.
 */
const isNotNullOrEmpty = (string) => {
  return string !== null && string !== '';
};

/**
 * Checks if src is adwords.
 * @return false if not adwords.
 */
const isAdwords = (src) => {
  return ['adwords', 'googleads', 'bingads'].includes(
    String(src).toLowerCase(),
  );
};

/**
 * removes referrer's protocol for cleaner data
 * @return referrer without http:// | https://
 */
const removeProtocol = (href) => {
  return href.replace(/.*?:\/\//g, '');
};

/**
 * Sets a new cookie. Receives cookie name and value.
 */
export const setCookie = (cookie, value) => {
  const domain = window.location.host;
  const expires = new Date();

  expires.setTime(expires.getTime() + 62208000000); // 1000*60*60*24*30*24 (2 years)
  document.cookie = `${cookie}=${value}; expires=${expires.toGMTString()} ; domain=${domain}; path=/; SameSite=Lax`;
};

/**
 * Receives a query string parameter name.
 * @return value of given query string parameter (if true); null if query string parameter is not present.
 */
export const getURLParameter = (param) => {
  const pageURL = window.location.search.substring(1);
  const URLVariables = pageURL.split('&');

  for (let i = 0; i < URLVariables.length; i += 1) {
    const parameterName = URLVariables[i].split('=');

    if (parameterName[0] === param) {
      return parameterName[1];
    }
  }
  return null;
};

/**
 * Parse adwords info in querystring.
 * @return null or string.
 */
const getAdwordsInfo = () => {
  let data = '';
  const keys = [
    'url',
    'medium',
    'device',
    'keyword',
    'creative',
    'matchtype',
    'adgroupid',
    'campaignid',
    'devicemodel',
  ];

  keys.forEach((k) => {
    data += `>>${k}=${getURLParameter(k)}`;
  });

  if (isNotNullOrEmpty(data)) {
    return data;
  }

  return null;
};

/**
 * set tracking data in local/session storage
 * @return string
 */
export const getTrackingData = (urlParamSRC) => {
  const platform = `${navigator.appCodeName} ${navigator.platform}`;
  let landingPage = window.sessionStorage.getItem('landingPage');
  let trafficSource = '';

  if (isNotNullOrEmpty(urlParamSRC)) {
    trafficSource = `src=${urlParamSRC}`;

    // check if is an Adwords Campaign
    if (isAdwords(urlParamSRC)) {
      trafficSource += getAdwordsInfo();
    }
  } else if (isNotNullOrEmpty(document.referrer)) {
    trafficSource = `src=${removeProtocol(document.referrer)}`;
  } else {
    trafficSource = 'src=none or direct';
  }

  trafficSource += `>>agent=${platform.replace(/\s/g, '_')}`;

  if (landingPage === null) {
    sessionStorage.setItem('landingPage', window.location.pathname);
    landingPage = window.location.pathname;
  }
  trafficSource += `>>landing_page=${landingPage}`;

  trafficSource = trafficSource
    .split('>>')
    .map((item) => {
      const itemData = item.split('=');
      return `${itemData[0]}=${itemData[1]}`;
    })
    .join('>>');

  return trafficSource;
};

export const parseTrackingCookie = (cookieName) => {
  const data = {};
  const name = `${cookieName}=`;
  const cookieArray = document.cookie.split(';'); // break cookie into array
  let cookieContent = '';

  for (let i = 0; i < cookieArray.length; i += 1) {
    const cookie = cookieArray[i].replace(/^\s+|\s+$/g, ''); // replace all space with '' = delete it
    if (cookie.indexOf(name) === 0) {
      cookieContent = cookie.substring(name.length, cookie.length);
    }
  }

  if (!cookieContent) {
    return null;
  }

  const trackingInfo = cookieContent.split('>>');
  for (let i = 0; i < trackingInfo.length; i += 1) {
    const [key, value] = trackingInfo[i].split('=');
    data[key] = value;
  }

  if (data !== null) {
    return data;
  }

  return null;
};
