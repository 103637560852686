import React from 'react';
import { node, string } from 'prop-types';

import Header from './Header';
import Footer from './Footer';

const propTypes = {
  children: node.isRequired,
  path: string.isRequired,
};

const Layout = ({ children, path }) => {
  return (
    <>
      <Header currentPath={path} />
      <main className="ui-layout__main">{children}</main>
      <Footer />
    </>
  );
};

Layout.propTypes = propTypes;

export default Layout;
