import { useEffect } from 'react';
import {
  isRealReferrer,
  getTrackingData,
  setCookie,
  getURLParameter,
} from './utils';

const TrafficSource = () => {
  useEffect(() => {
    if (isRealReferrer()) {
      const urlParamSRC = getURLParameter('src');
      const cookieData = getTrackingData(urlParamSRC);

      setCookie('traffic_source', cookieData);
    }
  }, []);

  return null;
};

export default TrafficSource;
