import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { useWindowScroll } from 'react-use';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FaPhoneSquareAlt } from '@react-icons/all-files/fa/FaPhoneSquareAlt';
import { FaRegEnvelope } from '@react-icons/all-files/fa/FaRegEnvelope';
import mweLogo from '../../images/site-core/mwe-logo.svg';

const propTypes = {
  currentPath: string.isRequired,
};

const query = graphql`
  query NavMenuQuery {
    allWpMenuItem(
      filter: {
        locations: { eq: GATSBY_HEADER_MENU }
        parentDatabaseId: { eq: 0 }
      }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        id
        path
        label
        childItems {
          nodes {
            id
            path
            label
          }
        }
      }
    }
  }
`;

const Header = ({ currentPath }) => {
  const { y } = useWindowScroll();
  const [bgNav, setBgNav] = useState();
  const { allWpMenuItem } = useStaticQuery(query);
  const navItems = allWpMenuItem.nodes;

  useEffect(() => {
    const current = y > 50 ? 'bg-white border-bottom' : undefined;
    setBgNav(current);
  }, [y]);

  return (
    <header>
      <div className="d-none d-xl-flex c-topbar">
        <Container fluid="xl text-end">
          <ul className="c-topbar-items">
            <li className="c-topbar-item font-size-xs">
              <div className="c-topbar-item-bullet bg-secondary" />
              <a
                href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x80dc019322692135:0x5c7c05e483f2672?source=g.page.m"
                target="_blank"
                rel="noreferrer"
                className="text-dark"
              >
                Dallas, TX 75225
              </a>
            </li>
            <li className="c-topbar-item font-size-xs">
              <div className="c-topbar-item-bullet bg-primary" />
              <span>1-866-932-9944</span>
            </li>
          </ul>
        </Container>
      </div>

      <Navbar fixed="top" expand="xl" collapseOnSelect className={bgNav}>
        <Container fluid="xl">
          <Navbar.Brand as={Link} to="/">
            <img src={mweLogo} alt="Medical Web Experts" />
          </Navbar.Brand>
          <div className="d-lg-none d-flex align-items-center">
            <div className="d-lg-none me-2">
              <Link to="/contact-us/" className="text-dark">
                <FaRegEnvelope size="24" />
              </Link>
            </div>
            <Navbar.Toggle aria-controls="navbar-nav" />
          </div>
          <Navbar.Collapse id="navbar-nav" className="flex-grow-0">
            {navItems.length > 0 && (
              <Nav>
                {navItems.map((navItem) => {
                  const items = navItem.childItems.nodes;

                  if (items.length > 0) {
                    const active = items.find((i) => i.path === currentPath);

                    return (
                      <NavDropdown
                        key={navItem.id}
                        id={navItem.id}
                        title={navItem.label}
                        active={!!active}
                      >
                        {items.map((item) => {
                          return (
                            <NavDropdown.Item
                              key={item.id}
                              eventKey={item.path}
                              as={Link}
                              to={item.path}
                              activeClassName="active"
                            >
                              {item.label}
                            </NavDropdown.Item>
                          );
                        })}
                      </NavDropdown>
                    );
                  }

                  return (
                    <Nav.Link
                      key={navItem.id}
                      eventKey={navItem.path}
                      as={Link}
                      to={navItem.path}
                      activeClassName="active"
                    >
                      {navItem.label}
                    </Nav.Link>
                  );
                })}
              </Nav>
            )}
            <ul className="d-block d-xl-none c-call-us">
              <li className="c-call-us-item">Call Us</li>
              <li className="c-call-us-item">
                <FaPhoneSquareAlt size={20} className="me-2 text-accent" />
                <span className="font-size-sm fw-normal font-family-base">
                  1-866-932-9944
                </span>
              </li>
            </ul>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

Header.propTypes = propTypes;

export default Header;
