import React from 'react';
import { node } from 'prop-types';
import SSRProvider from 'react-bootstrap/SSRProvider';

import TrafficSource from './components/TrafficSource/TrafficSource';

const propTypes = {
  element: node.isRequired,
};

const Root = ({ element }) => (
  <SSRProvider>
    {element}
    <TrafficSource />
  </SSRProvider>
);

Root.propTypes = propTypes;

export default Root;
